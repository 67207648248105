import React from "react";
import Header from "./Header";
import Footer from "./Footer";

const BAA = () => {
    return (
        <div className="bg-gradient-to-b from-scribe-gradient-start via-scribe-gradient-middle1 to-scribe-gradient-end">
            <div className="">
                <Header />
                <div className="w-full bg-white p-2">
                    <div className="w-full p-2 flex items-center justify-center">
                        <h1 className="text-2xl font-semibold underline">BAA</h1>
                    </div>
                    <div className="w-full p-2 flex flex-col">
                        <h2 className="text-base text-justify font-semibold mx-4">Sub-Heading</h2>
                        <p className="text-sm text-justify mx-4 px-1">
                            dummy text dummy text dummy text dummy text dummy text dummy text dummy text dummy text
                            dummy text dummy text dummy text dummy text dummy text dummy text dummy text dummy text
                            dummy text dummy text dummy text dummy text dummy text dummy text dummy text dummy text
                            dummy text dummy text dummy text dummy text dummy text dummy text dummy text dummy text
                            dummy text dummy text dummy text dummy text dummy text dummy text dummy text dummy text
                            dummy text dummy text dummy text dummy text dummy text dummy text dummy text dummy text
                            dummy text dummy text dummy text dummy text dummy text dummy text dummy text dummy text
                            dummy text dummy text dummy text dummy text dummy text dummy text dummy text dummy text
                            dummy text dummy text dummy text dummy text dummy text dummy text dummy text dummy text
                            dummy text dummy text dummy text dummy text dummy text dummy text dummy text dummy text
                            dummy text dummy text dummy text dummy text dummy text dummy text dummy text dummy text
                        </p>
                    </div>
                    <div className="w-full p-2 flex flex-col">
                        <p className="text-sm text-justify mx-4 px-1">
                            dummy text dummy text dummy text dummy text dummy text dummy text dummy text dummy text
                            dummy text dummy text dummy text dummy text dummy text dummy text dummy text dummy text
                            dummy text dummy text dummy text dummy text dummy text dummy text dummy text dummy text
                            dummy text dummy text dummy text dummy text dummy text dummy text dummy text dummy text
                            dummy text dummy text dummy text dummy text dummy text dummy text dummy text dummy text
                            dummy text dummy text dummy text dummy text dummy text dummy text dummy text dummy text
                            dummy text dummy text dummy text dummy text dummy text dummy text dummy text dummy text
                            dummy text dummy text dummy text dummy text dummy text dummy text dummy text dummy text
                            dummy text dummy text dummy text dummy text dummy text dummy text dummy text dummy text
                            dummy text dummy text dummy text dummy text dummy text dummy text dummy text dummy text
                            dummy text dummy text dummy text dummy text dummy text dummy text dummy text dummy text
                        </p>
                    </div>
                    <div className="w-full p-2 flex flex-col">
                        <h2 className="text-base text-justify font-semibold mx-4">Sub-Heading</h2>
                        <p className="text-sm text-justify mx-4 px-1">
                            dummy text dummy text dummy text dummy text dummy text dummy text dummy text dummy text
                            dummy text dummy text dummy text dummy text dummy text dummy text dummy text dummy text
                            dummy text dummy text dummy text dummy text dummy text dummy text dummy text dummy text
                            dummy text dummy text dummy text dummy text dummy text dummy text dummy text dummy text
                            dummy text dummy text dummy text dummy text dummy text dummy text dummy text dummy text
                            dummy text dummy text dummy text dummy text dummy text dummy text dummy text dummy text
                            dummy text dummy text dummy text dummy text dummy text dummy text dummy text dummy text
                            dummy text dummy text dummy text dummy text dummy text dummy text dummy text dummy text
                            dummy text dummy text dummy text dummy text dummy text dummy text dummy text dummy text
                            dummy text dummy text dummy text dummy text dummy text dummy text dummy text dummy text
                            dummy text dummy text dummy text dummy text dummy text dummy text dummy text dummy text
                        </p>
                        <p className="text-sm text-justify mx-4 px-1">
                            dummy text dummy text dummy text dummy text dummy text dummy text dummy text dummy text
                            dummy text dummy text dummy text dummy text dummy text dummy text dummy text dummy text
                            dummy text dummy text dummy text dummy text dummy text dummy text dummy text dummy text
                            dummy text dummy text dummy text dummy text dummy text dummy text dummy text dummy text
                            dummy text dummy text dummy text dummy text dummy text dummy text dummy text dummy text
                            dummy text dummy text dummy text dummy text dummy text dummy text dummy text dummy text
                            dummy text dummy text dummy text dummy text dummy text dummy text dummy text dummy text
                            dummy text dummy text dummy text dummy text dummy text dummy text dummy text dummy text
                            dummy text dummy text dummy text dummy text dummy text dummy text dummy text dummy text
                            dummy text dummy text dummy text dummy text dummy text dummy text dummy text dummy text
                            dummy text dummy text dummy text dummy text dummy text dummy text dummy text dummy text
                        </p>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default BAA;
