
const LOCAL_SERVER_PORT = 8080;
const LIVE = false;
const SERVER_BASE_URL_Local = `http://localhost:${LOCAL_SERVER_PORT}/`;
// const SERVER_BASE_URL_Live = 'https://tibb-scribe-server-731784686092.us-central1.run.app/';
const SERVER_BASE_URL_Live = `http://localhost:${LOCAL_SERVER_PORT}/`;

export const BACKEND_URLS = {
    CONTACT: `${LIVE ? SERVER_BASE_URL_Live : SERVER_BASE_URL_Local}send-contact-message`,
};
