// firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
// import { getStorage } from "firebase/storage";

const scribeFirebaseConfig = {
    apiKey: "AIzaSyBGQmfLOUlNOtOogHfeo7Q7hLnivATwgQc",
    authDomain: "tibb-scribe-442417.firebaseapp.com",
    databaseURL: "https://tibb-scribe-442417-default-rtdb.firebaseio.com",
    projectId: "tibb-scribe-442417",
    storageBucket: "tibb-scribe-442417.firebasestorage.app",
    messagingSenderId: "731784686092",
    appId: "1:731784686092:web:57b32c2192bba38c919c29"
};

// Initialize Firebase
const scribeApp = initializeApp(scribeFirebaseConfig);

// Firestore instance
export const scribeDB = getFirestore(scribeApp);
export const scribeAuth = getAuth(scribeApp);
// export const storage = getStorage(app);

// Google SSO
const scribeGoogleAuthprovider = new GoogleAuthProvider();

export const signInWithGoogle = async () => {
    try {
        const result = await signInWithPopup(scribeAuth, scribeGoogleAuthprovider);
        const user = result.user;

        console.log("User signed in:", result.user);

        await createAndFetchUserToken(user.uid);

        return result;
        // await signInWithRedirect(auth, provider);
    } catch (error) {
        console.error("Error during sign-in:", error);
    }
};

const createAndFetchUserToken = async (userId) => {
    const body = {
        userId: userId
    };

    const headers = {
        "Content-Type":"application/json"
    };

    try {
        const response = await fetch("http://localhost:8080/get-custom-token",  {
            method: "POST",
            headers: headers,
            body: JSON.stringify(body)
        });

        const result = await response.json();
        const customToken = result.customToken;

        console.log("///////// Cutom token => ", customToken);
        
        // Set the Firebase ID Token in a secure cookie
        document.cookie = `firebaseCustomToken=${customToken}; path=/; secure; samesite=strict`;
        localStorage.setItem("firebaseCustomToken", customToken);

        return;
    } catch (error) {
        console.error("Error while fetching custom token => ", error);
    }
}
