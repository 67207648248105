import React from "react";
import Header from "./Header";
import Footer from "./Footer";

const PrivacyPolicy = () => {
    return (
        <div className="bg-background_main">
            <div className="">
                <Header />
                <div className="w-full bg-gray-200 flex items-center justify-center">
                    <div className="md:w-1/2 bg-white mx-5 md:mx-0">
                        <div className="w-full p-2 flex items-center justify-center">
                            <h1 className="text-4xl font-semibold">Privacy Policy</h1>
                        </div>

                        <div className="w-full px-2 flex items-center justify-center">
                            <h1 className="text-base font-semibold text-gray-500">Tibb Lab's Privacy Policy</h1>
                        </div>

                        <div className="w-full p-2 flex flex-col">
                            <p className="text-sm text-justify mx-4 px-1 my">
                                Tibb Labs’s Privacy Policy describes how Tibb Labs collects, uses, and discloses your information.
                            </p>
                            <p className="text-sm text-justify mx-4 px-1 my">
                                This Privacy Policy also explains your choices surrounding how we use your personal information, which include how you can object to certain uses of the information and how you can access and update certain information.
                            </p>
                            <p className="text-sm text-justify mx-4 px-1 font-bold my">
                                Here are some important definitions to help you understand our terms and this Privacy Policy:
                            </p>
                            <p className="text-sm text-justify mx-4 px-1 my">
                                <ul className="list-disc pl-5">
                                    <li>Tibb Labs, Inc., and our relevant affiliates are referred to as <q><b>Tibb Labs</b></q>, <q><b>we</b></q>, <q><b>us</b></q>, and <q><b>our</b></q>.</li>
                                    <li>Our public websites, including www.Tibb.io, are referred to as the <q><b>Website</b></q>.</li>
                                    <li>Our online software-as-service platform including any related APIs provided by Tibb Labs, together with all related mobile and desktop applications are collectively referred to as the <q><b>Services</b></q>.</li>
                                    <li>Content submitted, posted or modified by users in the Service is organized into separated sections that are referred to as <q><b>Workspaces</b></q>.</li>
                                </ul>
                            </p>
                        </div>

                        <div className="w-full p-2 flex flex-col">
                            <h2 className="text-base text-justify font-bold mx-4 p-1">1. Information we collect</h2>
                            <p className="text-sm text-justify mx-5 p-1">
                                We collect information when you provide it to us, when you use our Website or Services, and when other sources provide it to us, as further described below.
                            </p>
                        </div>

                        <div className="w-full p-2 flex flex-col">
                            <h4 className="text-sm text-justify mx-6 px-1 font-semibold">A. Information You Provide to Us</h4>
                            <p className="text-sm text-justify mx-7 p-1">
                                <b>Account Creation:</b> When you create an account or otherwise use the Services, we collect information such as your name, email address, password, role within your team or enterprise, and an optional profile photo.
                            </p>
                            <p className="text-sm text-justify mx-7 p-1">
                                <b>Your Communications with Us:</b> We collect information from you such as email address, phone number, or mailing address when you request information about our Services, register for our newsletter, request customer or technical support, or otherwise communicate with us. We also collect the contents of messages or attachments that you may send to us, as well as other information you choose to provide, and that may be associated with your communications.
                            </p>
                            <p className="text-sm text-justify mx-7 p-1">
                                <b>Payment Information:</b> When you purchase a subscription to the Services, you will need to provide payment information (e.g., financial account details, shipping information) to process your subscription. We use third-party payment providers to process payments on the Services. We may receive information associated with your payment information, such as billing address and transaction information, but we do not directly store payment information on the Services. Payment information is stored and processed by our payment providers on our behalf.
                            </p>
                            <p className="text-sm text-justify mx-7 p-1">
                                <b>Surveys:</b> We may contact you to participate in surveys. If you decide to participate, you may be asked to provide certain information which may include contact information and other information about you.
                            </p>
                            <p className="text-sm text-justify mx-7 p-1">
                                <b>Interactive Features:</b> We may offer interactive features such as forums, blogs, chat and messaging services, and social media pages. We and others who use our Website or Services may collect the information you submit or make available through these interactive features. Any content you provide via the public sections of these features will be considered “public” and is not subject to the privacy protections referenced herein. By using these interactive features, you understand that the information provided by you may be viewed and used by third parties for their own purposes.
                            </p>
                            <p className="text-sm text-justify mx-7 p-1">
                                <b>Registration for Sweepstakes or Contests:</b> We may run sweepstakes and contests. Contact information you provide may be used to inform you about the sweepstakes or contest and for other promotional, marketing and business purposes.
                            </p>
                            <p className="text-sm text-justify mx-7 p-1">
                                <b>Conferences, Trade Shows, and Other Events:</b> We may attend conferences, trade shows, and other events where we collect contact information from individuals who interact with or express an interest in the Services.
                            </p>
                            <p className="text-sm text-justify mx-7 p-1">
                                <b>Job Applications:</b> We may post job openings and opportunities on the Website or Services. If you reply to one of these postings by submitting your application, CV and/or cover letter to us, we will collect and use your information to assess your qualifications.
                            </p>
                        </div>

                        <div className="w-full p-2 flex flex-col">
                            <h4 className="text-sm text-justify mx-6 px-1 font-semibold">B. Information Collected Automatically</h4>
                            <p className="text-sm text-justify mx-7 p-1">
                                <b>Automatic Data Collection:</b> We keep track of certain information about you when you visit and interact with our Website or Services. This information may include your Internet protocol (IP) address, user settings, MAC address, cookie identifiers, mobile carrier, mobile advertising and other unique identifiers, details about your browser, operating system or device, location information (including inferred general location based off of your IP address), Internet service provider, pages that you visit before, during and after using the Website or Services, information about the links you click, information about how you interact with the Website or Services, including the frequency and duration of your activities, and other information about how you use the Website or Services. Information we collect may be associated with accounts and other devices.
                            </p>
                            <p className="text-sm text-justify mx-7 p-1">
                                <b>Cookies, Pixel Tags/Web Beacons, and Analytics Information:</b> We, as well as third parties that provide content, advertising, or other functionality on the Services, may use cookies, pixel tags, local storage, and other technologies (“Technologies”) to automatically collect information through the Website or Services. Technologies are essentially small data files placed on your devices that allow us and our partners to record certain pieces of information whenever you visit or interact with our Services. 
                                {/* For more information regarding these Technologies, please see our <a className="text-blue-500 underline hover:text-blue-700" href="https://tibb.io">Cookie Notice</a>. */}
                            </p>
                            <p className="text-sm text-justify mx-7 p-1">
                                <b>Analytics:</b> We may also use third-party service providers to collect and process analytics and other information on our Website or Services. These third-party service providers may use Technologies to collect and store analytics and other information. They have their own privacy policies addressing how they use the analytics and other information and we do not have access to, nor control over, third parties’ use of cookies or other tracking technologies. 
                                {/* For more information regarding third-party cookies, please see our Cookie Notice. */}
                            </p>
                            <p className="text-sm text-justify mx-7 p-1">
                                <b>Advertising:</b> In using the Website or Services, we may allow select third party advertising technology partners to place Technologies on the browser of your device to collect information about you as discussed above. Third parties (e.g., ad networks and ad servers) may use this information to serve relevant content and advertising to you as you browse the Internet, and access their own Technologies on your browser to assist in this activity. If you are interested in more information about these online advertising activities, and how you can generally control cookies from being put on your computer to deliver tailored advertising, you may visit the Network Advertising Initiative's Consumer Opt-Out link, the Digital Advertising Alliance's Consumer Opt-Out link, or Your Online Choices to opt-out of receiving tailored advertising from companies that participate in those programs. We do not control these opt-out links or whether any particular company chooses to participate in these opt-out programs. We are not responsible for any choices you make using these mechanisms or the continued availability or accuracy of these mechanisms. You can also opt out of certain online advertising activities by following the instructions in the "Your Choices" and "Your Privacy Rights" sections below. Please note that if you use these mechanisms, you may still see advertising on our Website or Services or across the Internet, but it will not be tailored to you based on your online behavior over time.
                            </p>
                        </div>

                        <div className="w-full p-2 flex flex-col">
                            <h4 className="text-sm text-justify mx-6 px-1 font-semibold">C. Information from Other Sources</h4>
                            <p className="text-sm text-justify mx-7 p-1">
                                We may obtain information about you from other sources, including through third-party services 
                                and organizations. For example, if you access our Website or Services through a third-party application, 
                                such as a social networking site or a third-party login service, we may collect information about you from 
                                that third party that you have made available via your privacy settings. In addition, if you register 
                                a Tibb Labs account or associate a Tibb Labs account with an email address provisioned by your 
                                organization, we may obtain information from third party data enrichment providers to help us to better 
                                tailor business-to-business marketing and sales outreach to you.
                            </p>
                            <p className="text-sm text-justify mx-7 p-1">
                                Should you choose to use the Google Contacts feature within our Services, we will have the ability to 
                                view your contacts via the Google People API. The sole use of this data is to populate the auto-completion 
                                of your contacts when sending invitation emails. Tibb Labs will not use this data for any other purpose.
                            </p>
                            <p className="text-sm text-justify mx-7 p-1">
                                Tibb Labs Calendar requires you to authorize access to your 
                                calendar and events data held in your third-party calendar service. Such third-party calendar service 
                                is a Non-Tibb Labs Service as that term is defined in the Agreement. Should you choose to authorize an 
                                integration with your calendar provider, we will store your userID for that calendar provider and the 
                                authorization token to connect to the calendar provider’s API to give you the ability to use Tibb Labs 
                                Calendar to store, view and edit data from your calendars, and autocomplete contacts when you create events.
                                </p>
                            <p className="text-sm text-justify mx-7 p-1">
                                You may also choose to integrate Tibb Labs Calendar with your 
                                third party conferencing provider. Such conferencing provider is a Non-Tibb Labs Service as that term is 
                                defined in the Agreement. Should you choose to authorize an integration with your conference provider, you 
                                will have the ability to add conferencing meetings to your calendar events, and to view and store any 
                                information associated with conferencing meeting invitations in Tibb Labs Calendar.
                                </p>
                        </div>

                        <div className="w-full p-2 flex flex-col">
                            <h2 className="test-base test-justify font-bold mx-4 p-1">2. How we use your information</h2>
                            <p className="text-sm text-justify mx-5 p-1">
                                We use your information for a variety of business purposes, including to:
                            </p>
                        </div>

                        <div className="w-full p-2 flex flex-col">
                            <h4 className="text-sm text-justify mx-6 px-1 font-semibold">Provide the Services or Requested Information, such as:</h4>
                            <p className="text-sm text-justify mx-7 p-1">
                                <ul className="list-disc pl-5">
                                    <li>Fulfilling our contract with you;</li>
                                    <li>Identifying and communicating with you, including providing newsletters and marketing materials;</li>
                                    <li>Managing your information;</li>
                                    <li>Processing your payments (which are processed and stored by our third-party payment processors on our behalf) and otherwise servicing your purchase orders;</li>
                                    <li>Responding to questions, comments, and other requests;</li>
                                    <li>Providing access to certain areas, functionalities, and features of our Services; and</li>
                                    <li>Answering requests for customer or technical support.</li>
                                </ul>
                            </p>
                        </div>

                        <div className="w-full p-2 flex flex-col">
                            <h4 className="text-sm text-justify mx-6 px-1 font-semibold">Serve Administrative and Communication Purposes, such as:</h4>
                            <p className="text-sm text-justify mx-7 p-1">
                                <ul className="list-disc pl-5">
                                    <li>Pursuing legitimate interests, such as research and development (including marketing research), network and information security, and fraud prevention.</li>
                                    <li>Sending communications about new product features, promotions, Tibb Labs's strategic partners, and other news about Tibb Labs.</li>
                                    <li>Measuring interest and engagement in our Services, including analyzing your usage of the Services.</li>
                                    <li>Developing new products and services and improving the Services.</li>
                                    <li>Ensuring internal quality control and safety.</li>
                                    <li>Authenticating and verifying individual identities.</li>
                                    <li>Carrying out audits.</li>
                                    <li>Communicating with you about your account, activities on our Services and Privacy Policy changes.</li>
                                    <li>Preventing and prosecuting potentially prohibited or illegal activities.</li>
                                    <li>Enforcing our agreements; and</li>
                                    <li>Complying with our legal obligations</li>
                                </ul>
                            </p>

                            <p className="text-sm text-justify mx-5 p-1">
                                <b>Facilitating Collaboration in a Workspace:</b> If you join a Workspace owned by another user or organization, or collaborate with other users on Tibb Labs, Tibb Labs may share basic information about you such as your name, email address, profile picture, Workspace name and Workspace ID with the other users or organizations.
                            </p>
                            <p className="text-sm text-justify mx-5 p-1">
                                <b>Managing Workspaces for Organizations:</b> If you register a Tibb Labs account or associate a Tibb Labs account with an email address provisioned by your organization, Tibb Labs may share information about you and any Workspaces owned or managed by you with your organization.  Such information could include your name, email address, and profile picture, as well as the Workspace name, Workspace ID, membership size, creation date of your Workspace, email address, and content within your Workspace with your organization. If you registered to use Tibb Labs’s Services with such an email address, but you do not use the Services in connection with your organization and you do not wish for this information to be shared with your organization, you may transfer your account to a different email address. 
                                {/* For information on how to change the email address associated with your Tibb Labs account please follow the instructions here: <a className="text-blue-500 underline hover:text-blue-700" href="https://www.tibb.io/">https://www.Tibb.io/help/account-settings</a>. If you would like additional assistance, please contact <a href="mailto:privacy@tibb.io" className="text-blue-500 underline hover:text-blue-700">privacy@tibb.io</a> */}
                            </p>
                            <p className="text-sm text-justify mx-5 p-1">
                                <b>Marketing of Products and Services:</b> In accordance with applicable legal requirements, we may use your information to tailor and provide you with content and advertisements. If you have any questions about our marketing practices or if you would like to opt out of the use of your information for marketing purposes, you may contact us as set forth below.
                            </p>
                            <p className="text-sm text-justify mx-5 p-1">
                                <b>Consent:</b> We may use personal information for other purposes that are clearly disclosed to you at the time you provide personal information or with your consent.
                            </p>
                            <p className="text-sm text-justify mx-5 p-1">
                                <b>De-identified and Aggregated Information Use:</b> We may use de-identified and/or aggregated information that can no longer be reasonably linked to you or your device from the information we collect. De-identified and/or aggregated information is not subject to this Privacy Policy, and we may use and disclose such information in a number of ways, including research, internal analysis, analytics, and any other legally permissible purposes.
                            </p>
                            <p className="text-sm text-justify mx-5 p-1">
                                <b>Process Information on Behalf of Our Customers:</b> Our customers may choose to use our Services to process certain data of their own, which may contain information from or about you. Such personal information that is processed by us on behalf of our customers, and our privacy practices will be governed by the contracts that we have in place with our customers, not this Privacy Policy.
                            </p>
                            <p className="text-sm text-justify mx-5 p-1">
                                If you have any questions or concerns about how such information is handled or would like to exercise your rights, you should contact the person or entity (i.e., the data controller) who has contracted with us to use the Service to process this information. Our customers control the information in these cases and determine the security settings within the account, its access controls and credentials. We will, however, provide assistance to our customers to address any concerns you may have, in accordance with the terms of our contract with them.
                            </p>
                            {/* <p className="text-sm text-justify mx-5 p-1">
                                <b>Subprocessors:</b> For more information regarding our Subprocessors, please see our <a className="text-blue-500 underline hover:text-blue-700" href="https://www.tibb.io/">Subprocessor Page.</a>
                            </p> */}
                            <p className="text-sm text-justify mx-5 p-1">
                                <b>How We Use Automatic Collection Technologies:</b> Our uses of Technologies fall into the following general categories:
                            </p>
                            <p className="text-sm text-justify mx-5 p-1">
                                <ul className="list-disc pl-5">
                                    <li>Operationally Necessary;</li>
                                    <li>Performance Related;</li>
                                    <li>Functionality Related; and</li>
                                    <li>Marketing Related.</li>
                                </ul>
                            </p>
                            {/* <p className="text-sm text-justify mx-5 p-1">
                                For more information, please see our <a className="text-blue-500 underline hover:text-blue-700" href="https://tibb.io/">Cookie Notice.</a>
                            </p> */}
                            <p className="text-sm text-justify mx-5 p-1">
                                <b>Cross-Device Tracking:</b> Your browsing activity may be tracked across different websites and different devices or apps. For example, we may attempt to match your browsing activity on your mobile device with your browsing activity on your laptop. To do this our technology partners may share data, such as your browsing patterns, geo-location and device identifiers, and will match the information of the browser and devices that appear to be used by the same person.
                            </p>
                        </div>

                        <div className="w-full p-2 flex flex-col">
                            <h2 className="test-base test-justify font-bold mx-4 p-1">3. Disclosing your information</h2>
                            <p className="text-sm text-justify mx-5 p-1">
                                We may disclose your information to the following categories of third parties:
                            </p>
                        </div>

                        <div className="w-full p-2 flex flex-col">
                            <p className="text-sm text-justify mx-5 p-1">
                                <b>Service Providers:</b> We may disclose information we collect about you to our third-party service providers. The categories of service providers to whom we entrust your information include service providers for: (i) the provision of the Services; (ii) the provision of information, products, and other services you have requested, including Non-Tibb Labs Services as that term is defined in the Agreement; (iii) marketing and advertising; (iv) payment and transaction processing; (v) customer service activities; (vi) the provision of IT and related services; and (vii) fraud prevention and user authentication.
                            </p>
                            <p className="text-sm text-justify mx-5 p-1">
                                <b>Business Partners:</b> At your request, we may provide information to business partners to provide you with a product or service of interest to you. We may also provide information to business partners with whom we jointly offer products or services.
                            </p>
                            <p className="text-sm text-justify mx-5 p-1">
                                <b>Affiliates:</b> We may disclose information to our affiliated entities, who are under common ownership and/or control with us.
                            </p>
                            <p className="text-sm text-justify mx-5 p-1">
                                <b>Advertising Partners:</b> We do not disclose or use your information to advertise any third party’s products or services via the Services. However, as explained in detail in the “Information Collected Automatically” section above, we may disclose your information to third-party advertising partners to market our own Services and grow our Services’ user base, such as to provide targeted marketing about our own Services via third-party services. Please see the “Your Choices” and “Your Rights” sections below for more information and to opt out.
                            </p>
                            <p className="text-sm text-justify mx-5 p-1 font-bold">
                                We may disclose your information to other third parties, including other users, in the following circumstances:
                            </p>
                            <p className="text-sm text-justify mx-5 p-1">
                                <b>Organizational Workspaces:</b> We allow organizations to access the profile information of users within their workspace, including to allow such organization to validate that such user is actually authorized by such organization to use the Services.
                            </p>
                            <p className="text-sm text-justify mx-5 p-1">
                                <b>Share Content with Friends or Colleagues:</b> Our Services may allow you to provide information about your friends and may allow you to forward or share certain content with a friend or colleague, such as an invitation email. You agree to only provide information about a friend or colleague with their consent.
                            </p>
                            <p className="text-sm text-justify mx-5 p-1">
                                <b>Disclosures to Protect Us or Others:</b> We may access, preserve, and disclose any information we store in association with you to external parties if we, in good faith, believe doing so is required or appropriate to: (i) comply with law enforcement or national security requests and legal process, such as a court order or subpoena; (ii) protect your, our, or others’ rights, property, or safety; (iii) enforce our policies or contracts; (iv) collect amounts owed to us; or (v) assist with an investigation and prosecution of suspected or actual illegal activity.
                            </p>
                            <p className="text-sm text-justify mx-5 p-1">
                                <b>Disclosure in the Event of Merger, Sale, or Other Asset Transfer:</b> If we are involved in a merger, acquisition, financing due diligence, reorganization, bankruptcy, receivership, purchase or sale of some or all assets, or transition of service to another provider, then your information may be sold or transferred as part of such a transaction, as permitted by law and/or contract.
                            </p>
                        </div>

                        <div className="w-full p-2 flex flex-col">
                            <h2 className="test-base test-justify font-bold mx-4 p-1">4. International Data Transfers</h2>
                            <p className="text-sm text-justify mx-5 p-1">
                                All information processed by us may be transferred, processed, and stored anywhere in the world, including but not limited to, the United States or other countries, which may have data protection laws that are different from the laws where you live. When we engage in such transfers, we endeavor to safeguard your information consistent with the requirements of applicable laws.
                            </p>
                        </div>

                        <div className="w-full p-2 flex flex-col">
                            <h2 className="test-base test-justify font-bold mx-4 p-1">5. Your Choices</h2>
                            <p className="text-sm text-justify mx-5 p-1">
                                <b>General:</b> You may have the right to object to or opt out of certain uses of your information. Where you have consented to the processing of your information for a specific purpose, you may withdraw that consent at any time by contacting us as described below. Even if you opt out, we may still collect and use your information for other purposes that were not based on your consent.
                            </p>
                            <p className="text-sm text-justify mx-5 p-1">
                                <b>Email Communications:</b> If you receive an email from us and do not want to receive future emails from us, you can use the unsubscribe link found at the bottom of the email to opt out of receiving future emails. Note that you will continue to receive transaction-related emails regarding products or services you have requested. We may also send you certain non-promotional communications regarding us and our Services, and you will not be able to opt out of those communications (e.g., communications regarding the Services or updates to this Privacy Policy).
                            </p>
                            <p className="text-sm text-justify mx-5 p-1">
                                <b>Mobile Devices:</b> We may send you push notifications through our Apps. You may at any time opt out from receiving these types of communications by changing the settings on your mobile device. With your consent, we may also collect precise location information if you use our Apps. You may opt out of this collection by changing the settings on your mobile device.
                            </p>
                            <p className="text-sm text-justify mx-5 p-1">
                                <b>“Do Not Track”:</b> Your browser may offer you a “Do Not Track” option, which allows you to signal to operators of websites and web applications that you do not wish such operators to track certain of your online activities over time and/or across different websites. Like most online services, we do not currently respond to Do Not Track signals. However, as discussed below in the “Your Privacy Rights” section, we do honor legally recognized browser-based mechanisms (such as the Global Privacy Control designed to signal your opt out choices under certain state laws).
                            </p>
                            <p className="text-sm text-justify mx-5 p-1">
                                <b>Cookies:</b> You may stop or restrict the placement of Technologies on your device. Tibb Labs provides settings for you to update your preferences in our Services. These settings can be found in the footer of our website. If you have a Tibb Labs account and are logged in to the Services, you can access your Cookie settings in the “Settings & Members” menu under the “My notifications & settings” section. 
                                {/* For more information, please see our <a className="text-blue-500 underline hover:text-blue-700" href="https://tibb.io/cookies">Cookie Notice.</a> */}
                            </p>
                        </div>

                        <div className="w-full p-2 flex flex-col">
                            <h2 className="test-base test-justify font-bold mx-4 p-1">6. Your Privacy Rights</h2>

                            <p className="text-sm text-justify mx-5 p-1">
                                Depending upon your location and in accordance with applicable laws, you may have the right to:
                            </p>
                            <p className="text-sm text-justify mx-5 p-1">
                                <ul className="list-disc pl-5">
                                    <li><b>Access</b> information about you consistent with legal requirements. In addition, you may have the right in some cases to receive or have your electronic information transferred to another party.</li>
                                    <li><b>Request Correction</b> of your information where it is inaccurate or incomplete.</li>
                                    <li><b>Request Deletion</b> of your information, subject to certain exceptions prescribed by law.</li>
                                    <li><b>Request Restriction or Object to Processing</b> of your information.</li>
                                    <li><b>Not be Discriminated Against</b> by us for exercising your privacy rights.</li>
                                </ul>
                            </p>
                            <p className="text-sm text-justify mx-5 p-1">
                                If you would like to exercise any of these rights, please contact us as set forth below. We will process such requests in accordance with applicable laws, and in some circumstances may need to deny your request in whole or in party (e.g., if we have a legal obligation to maintain your information for a certain purpose). To protect your privacy, we will take steps to verify your identity before fulfilling your request, such as by requiring you to submit your request via your account. You have the right to submit a request through an authorized agent, but we will require the agent to provide us with your written permission, and we may need to confirm your identity before processing the agent’s request.
                            </p>
                            <p className="text-sm text-justify mx-5 p-1">
                                Depending on your location of residence, you may have the right to appeal our response to your request. In such circumstances, we will inform you of the right to appeal and the appeal process.
                            </p>
                            <p className="text-sm text-justify mx-5 p-1">
                                <b>Request restriction on selling or sharing of "personal information":</b>  As explained in the "Information Automatically Collected" section above, we provide information about your device and online browsing activities to third-party advertising providers for targeted advertising and related purposes, so that we can provide you with more relevant and tailored ads regarding our Services. The disclosure of your information to these third parties to assist us in providing these services may be considered a “sale” of personal information under applicable law or the processing/sharing of personal information for targeted advertising purposes.
                            </p>
                            <p className="text-sm text-justify mx-5 p-1">
                                If you would like to opt out of our online disclosure such as through cookie and pixel technology of your personal information for purposes that could be considered “sales” for those third parties' own commercial purposes, or “sharing” for purposes of targeted advertising, please click on the "Do Not Sell or Share My Info" link on the bottom of our website footer at <a className="text-blue-500 underline text-blue-700" href="https://tibb.io">www.Tibb.io/product</a>.
                            </p>
                            <p className="text-sm text-justify mx-5 p-1">
                                Please note that if you have a legally recognized browser-based opt out preference signal turned on via your device browser, (e.g., global privacy control) we recognize such preference in accordance with applicable law.
                            </p>
                        </div>

                        <div className="w-full p-2 flex flex-col">
                            <h2 className="test-base test-justify font-bold mx-4 p-1">7. Data Retention</h2>

                            <p className="text-sm text-justify mx-5 p-1">
                                We store the information we receive as described in this Privacy Policy for as long as you use our Services or as necessary to fulfill the purpose(s) for which it was collected, provide our Services, resolve disputes, establish legal defenses, conduct audits, pursue legitimate business purposes, enforce our agreements, and comply with applicable laws.                                
                            </p>
                        </div>

                        <div className="w-full p-2 flex flex-col">
                            <h2 className="test-base test-justify font-bold mx-4 p-1">8. Security of your Information</h2>

                            <p className="text-sm text-justify mx-5 p-1">
                                We take steps to ensure that your information is treated securely and in accordance with this Privacy Policy. Unfortunately, no system is 100% secure, and we cannot ensure or warrant the security of any information you provide to us. To the fullest extent permitted by applicable law, we do not accept liability for unauthorized disclosure.
                            </p>
                            <p className="text-sm text-justify mx-5 p-1">
                                By using the Services or providing information to us, you agree that we may communicate with you electronically regarding security, privacy, and administrative issues relating to your use of the Services. If we learn of a security system’s breach, we may attempt to notify you electronically by posting a notice on the Services, by mail or by sending an email to you.
                            </p>
                        </div>

                        <div className="w-full p-2 flex flex-col">
                            <h2 className="test-base test-justify font-bold mx-4 p-1">9. Third-Party Websites/Applications</h2>

                            <p className="text-sm text-justify mx-5 p-1">
                                The Services may contain links to other websites/applications and other websites/applications may reference or link to our Services. These third-party services are not controlled by us. We encourage our users to read the privacy policies of each website and application with which they interact. We do not endorse, screen or approve, and are not responsible for, the privacy practices or content of such other websites or applications. Visiting these other websites or applications is at your own risk.
                            </p>
                        </div>

                        <div className="w-full p-2 flex flex-col">
                            <h2 className="test-base test-justify font-bold mx-4 p-1">10. Children's Information</h2>

                            <p className="text-sm text-justify mx-5 p-1">
                                Our Services are intended for general audiences and are not directed at children. If we become aware that we have collected data without legally valid parental consent from children under an age where such consent is required, we will take reasonable steps to delete it as soon as possible.
                            </p>
                        </div>

                        <div className="w-full p-2 flex flex-col">
                            <h2 className="test-base test-justify font-bold mx-4 p-1">11. Supervisory Authority</h2>

                            <p className="text-sm text-justify mx-5 p-1">
                                If you are located in the European Economic Area or the UK, you have the right to lodge a complaint with a supervisory authority if you believe our processing of your information violates applicable law.
                            </p>
                        </div>

                        <div className="w-full p-2 flex flex-col">
                            <h2 className="test-base test-justify font-bold mx-4 p-1">12. Additional Information for California Residents</h2>

                            <p className="text-sm text-justify mx-5 p-1">
                                If you are a California resident, the California Consumer Privacy Act (“CCPA”) requires us to provide you with the following additional information about: (1) the purpose for which we use each category of “personal information” (as defined in the CCPA) we collect; and (2) the categories of third parties to which we (a) disclose such personal information for a business purpose, (b) “share” personal information for “cross-context behavioral advertising,” and/or (c) “sell” such personal information. Under the CCPA, “sharing” is defined as the targeting of advertising to a consumer based on that consumer’s personal information obtained from the consumer’s activity across websites, and “selling” is defined as the disclosure of personal information to third parties in exchange for monetary or other valuable consideration.  Our use of third-party online advertising services, which are described in detail above, may result in the disclosure of online identifiers (e.g., cookie data, IP addresses, device identifiers, and usage information) in a way that may be considered a “sale” or "sharing" under the CCPA.
                            </p>
                            <p className="text-sm text-justify mx-5 p-1">
                                For information about our purposes of use of personal information, see the "Information we collect" section above.
                            </p>
                            <p className="text-sm text-justify mx-5 p-1">
                                For purposes of the CCPA, we have collected the following categories of consumers’ personal information, and disclosed the personal information to the following categories of third parties for a business purpose, in the last 12 months:
                            </p>

                            <div className="text-sm my-5">
                                <table className="border border-1">
                                    <tr>
                                        <th className="border border-1 p-1">Category</th>
                                        <th className="border border-1 p-1">Entities to Which We Have Disclosed Personal Information for a Business Purpose</th>
                                        <th className="border border-1 p-1">Entities to Which We Have Sold/Shared Personal Information</th>
                                    </tr>
                                    <tr>
                                        <td className="border border-1 p-2">Identifiers</td>
                                        <td className="border border-1 p-2">Affiliates; service providers; advertising partners; entities for legal purposes; entities for business transactions; entities with your consent.</td>
                                        <td className="border border-1 p-2">Advertising partners</td>
                                    </tr>
                                    <tr>
                                        <td className="border border-1 p-2">Commercial information</td>
                                        <td className="border border-1 p-2">Affiliates; service providers; entities for legal purposes; entities for business transactions; entities with your consent.</td>
                                        <td className="border border-1 p-2">We do not sell/share.</td>
                                    </tr>
                                    <tr>
                                        <td className="border border-1 p-2">Internet or other electronic network activity.</td>
                                        <td className="border border-1 p-2">Affiliates; service providers; advertising partners; entities for legal purposes; entities for business transactions; entities with your consent.</td>
                                        <td className="border border-1 p-2">Advertising partners</td>
                                    </tr>
                                    <tr>
                                        <td className="border border-1 p-2">Geolocation data. (general location information, not precise GPS)</td>
                                        <td className="border border-1 p-2">Affiliates; service providers; advertising partners; entities for legal purposes; entities for business transactions; entities with your consent</td>
                                        <td className="border border-1 p-2">Advertising partners</td>
                                    </tr>
                                    <tr>
                                        <td className="border border-1 p-2">Sensory data</td>
                                        <td className="border border-1 p-2">Affiliates; service providers; entities for legal purposes; entities for business transactions; entities with your consent</td>
                                        <td className="border border-1 p-2">We do not sell/share</td>
                                    </tr>
                                    <tr>
                                        <td className="border border-1 p-2">Professional or employment-related information</td>
                                        <td className="border border-1 p-2">Affiliates; service providers; entities for legal purposes; entities for business transactions; entities with your consent</td>
                                        <td className="border border-1 p-2">We do not sell/share</td>
                                    </tr>
                                    <tr>
                                        <td className="border border-1 p-2">Inferences drawn from other personal information to create a profile about a consumer</td>
                                        <td className="border border-1 p-2">Affiliates; service providers; advertising partners; entities for legal purposes; entities for business transactions; entities with your consent</td>
                                        <td className="border border-1 p-2">Advertising partners</td>
                                    </tr>
                                </table>
                            </div>

                            <p className="text-sm text-justify mx-5 p-1">
                                Your Choices Regarding “Sharing” and “Selling”: You have the right to opt out of our sale/sharing of your personal information for purposes of online analytics and advertising by clicking on the "Do Not Sell or Share My Info" link on our website footer.  To opt out of the offline disclosure of your information to third parties for these purposes, please email us at <a className="text-blue-500 underline hover:text-blue-700" href="mailto:privacy@tibb.io">privacy@tibb.io</a>
                            </p>
                            <p className="text-sm text-justify mx-5 p-1">
                                Other CCPA Rights: If we ever offer any financial incentives in exchange for your personal information, we will provide you with appropriate information about such incentives.
                            </p>
                            <p className="text-sm text-justify mx-5 p-1">
                                The CCPA also allows you to limit the use or disclosure of your “sensitive personal information” (as defined in the CCPA) if your sensitive personal information is used for certain purposes.  Please note that we do not use or disclose sensitive personal information other than for business purposes for which you cannot opt out under the CCPA.
                            </p>
                            <p className="text-sm text-justify mx-5 p-1">
                                Please see the “Your Rights” section of our Policy above for information about the additional rights you have with respect to your personal information under California law and how to exercise them.
                            </p>
                            <p className="text-sm text-justify mx-5 p-1">
                                Retention of Your Personal Information. Please see the “Data Retention” section above.
                            </p>
                            <p className="text-sm text-justify mx-5 p-1">
                                Shine the Light Disclosure: The California "Shine the Light" law gives residents of California the right under certain circumstances to request information from us regarding the manner in which we disclose certain categories of personal information (as defined in the Shine the Light law) with third parties for their direct marketing purposes. We currently do not disclose your personal information to third parties for their own direct marketing purposes.
                            </p>
                        </div>

                        <div className="w-full p-2 flex flex-col">
                            <h2 className="test-base test-justify font-bold mx-4 p-1">13. Data Privacy Framework</h2>

                            <p className="text-sm text-justify mx-5 p-1">
                                Tibb Labs, Inc. has certified to the U.S. Department of Commerce that Tibb Labs adheres to (1) the EU-U.S. Data Privacy Framework with regard to the processing of personal information received from the European Union, (2) the UK Extension to the EU-U.S. Data Privacy Framework, with regard to the processing of personal information received from the United Kingdom (and Gibraltar), and to (3) the Swiss-U.S. Data Privacy Framework with regard to the processing of personal information received from Switzerland (the “DPF”). The Federal Trade Commission has jurisdiction over our compliance with the DPF.
                            </p>
                            <p className="text-sm text-justify mx-5 p-1">
                                We remain responsible for all the personal information we receive under the DPF and that we subsequently transfer to third parties acting as agents on our behalf if they process personal information in a manner inconsistent with the DPF principles, unless we prove we are not responsible for the event giving rise to the damage.
                            </p>
                            <p className="text-sm text-justify mx-5 p-1">
                                To learn more about the DPF program, and to view our certification, please visit <a className="text-blue-500 underline hover:text-blue-700" href="https://www.dataprivacyframework.gov/" target="_blank">Privacy Framework</a>
                            </p>
                            <p className="text-sm text-justify mx-5 p-1">
                                If you have any question about our practices in relation to our participation in the DPF, or should you have any complaint in that regard, we encourage you to contact us directly, as set forth below. For any complaint that we cannot resolve directly Tibb Labs, Inc. commits to cooperate and comply respectively with the advice of the panel established by the EU data protection authorities (DPAs) (for personal information received under the EU-US DPF), the UK Information Commissioner’s Office (ICO) and the Gibraltar Regulatory Authority (GRA)  (for personal information received under the UK Extension to the EU-US DPF), and the Swiss Federal Data Protection and Information Commissioner (FDPIC) (for personal information received under the Swiss-US DPF). Please contact us if you would like us to direct you to your data protection authority contacts.
                            </p>
                            <p className="text-sm text-justify mx-5 p-1">
                                You may, under certain conditions, invoke binding arbitration for complaints regarding DPF compliance not resolved by any of the other DPF mechanisms. Please review Annex I of the DPF for additional information:
                            </p>
                            <p className="text-sm text-justify mx-5 p-1">
                                <a className="text-blue-500 underline hover:text-blue-700" href="https://www.dataprivacyframework.gov/s/article/ANNEX-I-introduction-dpf?tabset-35584=2" target="_blank">https://www.dataprivacyframework.gov/s/article/ANNEX-I-introduction-dpf?tabset-35584=2</a>
                            </p>
                            <p className="text-sm text-justify mx-5 p-1">
                            </p>
                        </div>

                        <div className="w-full p-2 flex flex-col">
                            <h2 className="test-base test-justify font-bold mx-4 p-1">14. Changes to Our Privacy Policy</h2>

                            <p className="text-sm text-justify mx-5 p-1">
                                We may revise this Privacy Policy from time to time in our sole discretion. If there are any material changes to this Privacy Policy, we will notify you as required by applicable law. You understand and agree that you will be deemed to have accepted the updated Privacy Policy if you continue to use the Services after the new Privacy Policy takes effect.
                            </p>
                        </div>

                        <div className="w-full p-2 flex flex-col">
                            <h2 className="test-base test-justify font-bold mx-4 p-1">Contact us:</h2>

                            <p className="text-sm text-justify mx-5 p-1">
                                If you have any questions about our privacy practices or this Privacy Policy, please contact us at:
                            </p>
                            <p className="text-sm text-justify mx-5 p-1">
                                Tibb Labs, Inc. P.O.Box 410, Plainsboro, NJ 08536 United States <a className="text-blue-500 underline hover:text-blue-700" href="mailto:privacy@tibb.io">privacy@tibb.io</a>
                            </p>
                        </div>

                        <div className="w-full p-2 flex flex-col">
                            <p className="text-[11px] text-justify mx-5 px-1">
                                Last Updated: October 6, 2023
                            </p>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default PrivacyPolicy;
