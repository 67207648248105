import React from "react";

const ProductCard = ({ name, description, borderColor, onClick, classNames }) => {
    return (
        <div className="group w-full h-[270px]">
            <div
                onClick={onClick}
                className={`p-4 my-4 md:m-0 w-full h-[270px] rounded-lg shadow-md cursor-pointer group-hover:shadow-lg transform transition-transform duration-300 flex flex-col justify-between text-white ${classNames} perspective cursor-pointer group-hover:animate-spinLeft2`}
            >
                {/* Inner wrapper for 3D rotation */}
                <div className="relative flex flex-col items-center justify-center w-full h-full transition-transform duration-200 transform-style-preserve-3d rounded-2xl">
                    {/* Front side of the card */}
                    <div className="absolute inset-0 flex flex-row items-center justify-center md:p-4 text-center bg-cover text-white rounded-lg backface-hidden group-hover:hidden">
                        <h3 className="text-xl md:text-2xl font-bold">{name}</h3>
                    </div>

                    {/* Back side of the card */}
                    <div className="absolute inset-0 flex items-center justify-center md:p-2 text-center bg-cover text-white rounded-lg backface-hidden hidden group-hover:flex">
                        <p className="text-[15.6px] md:text-[13px] xmd:text-sm xlg:text-xl">{description}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductCard;
